import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default React.memo(() => {
  const footerRef = useRef(null);

  const listener = e => {
    const rect = document.body.getBoundingClientRect();
    if (rect.top < -200) {
      footerRef.current.classList.add('scrolled');
    } else {
      footerRef.current.classList.remove('scrolled');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <footer
      id="site-footer"
      className="page-footer d-none d-lg-block"
      ref={footerRef}
    >
      <div className="footer-left">
        <div className="note">
          <a href={`mailto:info@ds.agency`}>
            <span className="marked">info@ds.agency</span>
          </a>
        </div>
      </div>
    </footer>
  );
});
